@import '../../styles/variables/_';
@import '../../styles/base/mixins';

.agency-expertise {
  margin: 0 auto;
  max-width: 920px;

  &__content {
    display: flex;
    width: 100%;

    @media ($max-xs) {
      flex-direction: column;
      text-align: center;
    }
  }

  &__items {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    min-height: 690px;
    align-items: flex-start;

    @media ($max-xs) {
      justify-content: space-between;
    }
  }
}
