@mixin link-states($color) {
  &:hover,
  &:active,
  &:focus {
    color: $color;
    text-decoration: none;
  }
}

@mixin flex-basis($value) {
  flex-basis: $value;
  max-width: $value;
  width: $value;
}

@mixin reset-button {
  background: none;
  border: none;
  padding: 0;
  outline: 0;

  &:focus {
    outline: 0;
  }
}

@mixin reset-input {
  background: none;
  border: none;
  padding: 0;
  outline: 0;

  &:focus {
    outline: 0;
  }
}
