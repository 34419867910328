@import '../../styles/variables/_';
@import '../../styles/base/mixins';

.communication-management {
  &__window {
    &__content {
      padding: 50px 50px 20px 50px;

      @media ($max-sm) {
        padding: 20px 15px 30px 15px;
      }
    }
  }

  &__title {
    margin-bottom: 25px;
    font-size: $font-size-def;
    font-weight: 500;
    color: $color-text-lighter;

    @media ($max-xs) {
      font-size: $font-size-sm;
    }
  }

  .slider {
    margin: 0px -15px;

    &:not(.slick-initialized) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .slick {

    &-track {
      display: flex !important;
    }

    &-slide {
      outline: none;
    }

    &-arrow {
      width: 29px;
      height: 24px;
      top: auto;
      bottom: -30px;
      transition-duration: $anim-duration-def;

      &::before {
        content: none;
      }
    }

    &-disabled {
      transition-duration: $anim-duration-def;
      opacity: .5;
    }

    &-prev {
      left: 50%;
      transform: translate(-120%, -50%);
      background-image: url("../../assets/images/common/arrow-left.svg");
      background-repeat: no-repeat;
      background-size: cover;
    }

    &-next {
      right: 50%;
      transform: translate(120%, -50%);
      background-image: url("../../assets/images/common/arrow-right.svg");
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  &__slider-item {
    height: inherit;
    @media ($min-sm) {
      flex: 0 0 50%;
    }
  }
}
