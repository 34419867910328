//colors
$color-text-def: #D2D2D2;
$color-text-lighter: #fff;
$color-text-darker: #B2B2B2;
$color-text-darkest: rgba(178, 178, 178, 0.6);
$color-dark-20: rgb(20, 20, 20); // was #141414
$color-dark-25: rgb(25, 25, 25); // was #191919
$color-dark-30: rgb(30, 30, 30); // was #1E1D1D
$color-grey: #2F2E2E; // Same as #4E4E4E
$color-grey-light: #454545;

$color-red: #FF5A52;
$color-yellow: #E6C029;
$color-green: #53C22B;

$color-link: #2481CC;

$color-dark-transparent: rgba($color-dark-30, 0.92);

$font-family-def: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

$font-size-xxs: 1rem;
$font-size-xs: 1.2rem;
$font-size-sm: 1.4rem;
$font-size-def: 1.6rem;
$font-size-md: 1.8rem;
$font-size-lg: 2.0rem;
$font-size-xl: 2.4rem;
$font-size-xxl: 2.8rem;

//defaults
$border-radius-default: 5px;
$border-radius-sm: 3px;
$box-shadow-default: 0 7px 30px rgba(0, 0, 0, 0.25);

$blur-backdrop: saturate(180%) blur(20px);

//animations
$anim-duration-def: 225ms;
$anim-duration-long: 350ms;

//media variables
$min-xxs: 'min-width : 320px';
$min-xs: 'min-width : 576px';
$min-sm: 'min-width : 768px';
$min-md: 'min-width : 992px';
$min-lg: 'min-width : 1200px';
$min-xxl: 'min-width : 1600px';

$max-xxs: 'max-width : 320px';
$max-xs: 'max-width : 576px';
$max-sm: 'max-width : 767px';
$max-md: 'max-width : 991px';
$max-lg: 'max-width : 1199px';
$max-xxl: 'max-width : 1600px';
