@import "variables/_";
@import "base/animations";
@import "base/helpers";
@import "base/mixins";
@import "base/reset";
@import "other/loader-animation";

body {
  font-family: $font-family-def;
  font-size: $font-size-def;
  font-weight: 400;
  line-height: 1.4;
  color: $color-text-def;
  background-color: $color-dark-20;
}

#root {
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.container {
  max-width: 1250px !important;
}

.content {
  flex: 1 0 auto;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.15;
  font-family: $font-family-def;
}

.text-xxl {
  font-size: $font-size-xxl;
}

.text-xl {
  font-size: $font-size-xl;
}

.text-lg {
  font-size: $font-size-lg;
}

.text-md {
  font-size: $font-size-md;
}

.text-sm {
  font-size: $font-size-sm;
}

.text-xs {
  font-size: $font-size-xs;
}

.text-xxs {
  font-size: $font-size-xxs;
}

p {
  margin-bottom: 1.5em;
}

a {
  color: $color-link;
  text-decoration: none;

  &:hover {
    color: $color-link;
    text-decoration: underline;
  }
}

label {
  margin-bottom: 0;
}

.section-pad {
  padding-top: 120px;
  overflow: hidden;

  @media ($max-md) {
    padding-top: 90px;
  }

  @media ($max-sm) {
    padding-top: 70px;
  }

  @media ($max-xs) {
    padding-top: 50px;
  }
}

canvas {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 0;
  top: 0px;
}

.dg {
  display: none;
}

.clickable {
  cursor: pointer;
}
