@import '../../styles/variables/_';
@import '../../styles/base/mixins';


.onboarding-process {
  .window__content {
    overflow: initial;
  }

  &__content {
    padding: 50px 30px;
    display: flex;
    min-height: 350px;
    flex-direction: column;
    justify-content: space-between;

    @media ($max-xs) {
      padding: 30px 15px;
    }
  }

  &__window-step {
    max-width: 450px;
    min-height: 130px;
    transition-duration: 0.4s;
    margin-bottom: 40px;
    font-weight: 500;
    font-size: $font-size-sm;

    @media ($max-sm) {
      margin-left: auto;
      margin-right: auto;
    }

    &__content {
      display: flex;
    }

    &__emoji {
      width: 60px;
      height: 60px;
      object-fit: contain;

      @media ($max-xs) {
        width: 45px;
        height: 45px;
      }

      &_wrapper {
        padding-right: 25px;
        @media ($max-xs) {
          padding-right: 10px;
        }
      }
    }

    &__text {
      flex: 1;
    }

    &__btn-wrapper {
      margin-top: 15px;
      text-align: right;
    }

    @media ($min-sm) {
      &--step1 {
        left: 0;
        transform: translateX(-10%);
      }

      &--step2 {
        left: 25%;
        transform: translateX(-25%);
      }

      &--step3 {
        left: 50%;
        transform: translateX(-50%);
      }

      &--step4 {
        left: 75%;
        transform: translateX(-75%);
      }

      &--step5 {
        left: 100%;
        transform: translateX(-90%);
      }
    }
  }

  &__button-start-outer {
    text-align: center;
    padding-top: 40px;
  }
}
