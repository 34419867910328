@import '../../styles/variables/_';
@import '../../styles/base/mixins';

.modal-custom {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  pointer-events: none;

  @media ($max-md) {
    top: 50%;
    left: 50%;
    transform: translate(0, 0) !important;
  }

  &__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1050;
    //overflow: scroll;
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    transition: opacity 300ms ease-in-out;
    //backdrop-filter: $blur-backdrop;
  }

  &__window {
    transform-origin: 0 0;
    transform: translate(-50%, -50%);
    pointer-events: all;
    position: absolute;
    max-height: 90vh;

    @media ($max-md) {
      padding: 15px;
    }
  }

  &__text {
    padding: 10px 20px;
    background: $color-dark-25;
    font-size: $font-size-sm;
    margin: 15px 20px;
    overflow: auto;

    p:last-child {
      margin: 0;
    }

    @media ($max-xs) {
      //font-size: $font-size-xs;
      padding: 0;
      background: none;
    }
  }

  &-animation {
    &-enter {
      .modal-custom {
        &__bg {
          opacity: 0;
        }

        &__window {
          transform: scale(0);
        }
      }

      &-active {
        .modal-custom {
          &__bg {
            opacity: 1;
          }

          &__window {
            transform: scale(1) translate(-50%, -50%);
            transition: transform 300ms ease-in-out;
          }
        }
      }
    }

    &-leave {
      .modal-custom {
        &__bg {
          opacity: 1;
        }

        &__window {
          transform: scale(1) translate(-50%, -50%);
        }
      }

      &-active {
        .modal-custom {
          &__bg {
            opacity: 0;
          }

          &__window {
            transform: scale(0);
            transition: transform 300ms ease-in-out;
          }
        }
      }
    }
  }

  &--sm {
    max-width: 440px;
  }

  &--md {
    max-width: 700px;
  }

  &--lg {
    max-width: 950px;
  }

  &--centered {
    top: 50%;
    left: 50%;
    transform: translate(0, 0) !important;
  }
}

.noscroll-modal {
  overflow: hidden;
}
