@import '../../../styles/variables/_';
@import '../../../styles/base/mixins';

.services__body {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__top {
    background: $color-dark-25;
    padding: 7px 15px 7px 25px;

    @media ($max-md) {
      padding-left: 15px;
    }

    display: flex;
    align-items: center;
    justify-content: space-between;

    &__back-btn {
      display: none;
      @media ($max-sm) {
        display: block;
        @include reset-button;
        padding: 10px 15px 10px 0;
      }
    }

    &__info {
      display: flex;
      align-items: center;

      &_avatar {
        margin-right: 15px;
        width: 44px;
        height: 44px;
      }

      &_title {
        font-size: $font-size-lg;
        line-height: 1;
        margin-bottom: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        @media ($max-xs) {
          font-size: $font-size-md;
        }
      }

      &_subtitle {
        font-size: $font-size-xs;
        color: $color-link;
        font-weight: 300;
        line-height: 1;
      }
    }

    &__icons {
      @media ($max-xs) {
        display: none;
      }

      &_phone-icon, &_dots-icon {
        @include reset-button;
        padding: 5px;

        img {
          height: 14px;
        }
      }

      &_phone-icon {
        margin-right: 30px;
      }
    }
  }

  &__bottom {
    background: $color-dark-25;
    padding: 0 15px 0 25px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media ($max-md) {
      padding-left: 15px;
    }

    &__form {
      display: flex;
      flex: 1;
      align-items: center;
    }

    &__input {
      flex: 1;
      background: none;
      border: none;
      border-radius: 0;
      color: white;
      outline: none;
      font-size: $font-size-sm;
      margin: 0 15px 0 20px;
      padding: 10px 0;

      &::placeholder {
        color: rgba(178, 178, 178, 0.2);
      }
    }

    &__submit {
      @include reset-button;
    }

    &__emoji_btn {
      @include reset-button;
      margin-right: 20px;

      @media ($max-xs) {
        display: none;
      }
    }
  }

  &__main {
    position: relative;
    flex: 1;
    overflow-y: visible;
    overflow-x: hidden;
  }

  &__image {
    width: 100%;
    border-radius: 1%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
  }
}
