@import '../../../styles/variables/_';
@import '../../../styles/base/mixins';

.agency-expertise__item {
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 5px 10px;
  font-size: $font-size-sm;
  text-align: center;
  margin: 5px;
  transition-duration: $anim-duration-def;

  &:hover {
    background: $color-grey;
    border-radius: 4px;
  }

  &_img {
    width: 60px;
    height: 60px;
    margin-bottom: 12px;
    object-fit: contain;
  }

  &--white-bg &_img {
    background: white;
    border-radius: 6px;
    padding: 5px;
  }

  @media ($max-sm) {
    width: 90px;
    margin: 0;

    &_img {
      width: 50px;
      height: 50px;
    }
  }

  @media ($max-xs) {
    width: 80px;
  }
}
