@import '../../../styles/variables/_';
@import '../../../styles/base/mixins';

.window__circle-button {
  @include reset-button;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: $color-grey-light;
  margin-right: 10px;

  @media ($max-xs) {
    width: 12px;
    height: 12px;
    margin-right: 5px;
  }

  &s {
    display: flex;
    padding: 8px 0;
    z-index: 1;
    position: relative;
  }

  &--red{
    background-color: $color-red;
  }

  &--yellow {
    background-color: $color-yellow;
  }

  &--green {
    background-color: $color-green;
  }
}
