@import '../../styles/variables/_';
@import '../../styles/base/mixins';

.button {
  @include reset-button;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition-duration: $anim-duration-def;
  max-width: 100%;
  line-height: 1.2;
  text-align: center;

  &:hover {
    text-decoration: none;
  }

  &__wrapper {
    display: inline-block;
  }

  // button sizes
  &--xs {
    height: 22px;
    padding: 0 10px;
    border-radius: $border-radius-sm;
    font-size: $font-size-xs;
    font-weight: 500;
  }

  &--sm {
    height: 30px;
    padding: 0 15px;
    border-radius: $border-radius-default;
    font-size: $font-size-sm;
  }

  &--md {
    height: 40px;
    padding: 0 15px;
    border-radius: $border-radius-sm;
    font-size: $font-size-md;

    @media ($max-xs) {
      height: 30px;
    }
  }

  // button colors
  &--grey {
    background: $color-grey;
    border-top: 1px solid $color-grey-light;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
    color: $color-text-def;

    &:hover {
      color: $color-text-def;
    }
  }

  &--blue {
    background: linear-gradient(180deg, #2472E7 0%, #155FCF 100%);
    box-shadow: 0px -0.8px 0px #5454CA;
    color: $color-text-def;

    &:hover {
      color: $color-text-def;
    }
  }

  &--violet {
    background: linear-gradient(180deg, #6070D3 0%, #5251C9 100%);
    box-shadow: 0px -0.8px 0px #5454CA;
    color: $color-text-def;

    &:hover {
      color: $color-text-def;
    }
  }
}
