@import '../../../styles/variables/_';
@import '../../../styles/base/mixins';

.services__sidebar {
  max-width: 390px;
  min-width: 320px;
  width: 35%;
  border-right: 1px solid rgba(0, 0, 0, 0.5);

  &__top {
    display: flex;
    align-items: center;
    font-size: $font-size-md;
    padding: 10px 15px;
    background: $color-dark-25;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);

    &_avatar {
      margin-right: 15px;
    }
  }
}
