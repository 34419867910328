@import '../../styles/variables/_';
@import "Navigation.variables";

.navigation {
  background-color: $nav-top-bg;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
  transition-duration: $anim-duration-def;
  color: $nav-top-color;
  z-index: 1032;
  padding: 15px 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  &__top {
    width: 100%;

    &__container {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }

    &__logo {
      margin-right: 15px;
      max-width: 32px;
      width: 100%;
      transition-duration: $anim-duration-def;

      img {
        width: 100%;
      }
    }

    &__item {
      min-width: 130px;
      margin: 0 9px;

      @media ($max-lg) {
        min-width: 110px;
      }

      &s {
        display: flex;
      }
    }
  }

  @media (min-width: $nav-collapse + 1) {
    &.nav-scrolled {
      padding-top: 25px;
    }

    &__top__toggle {
      display: none;
    }
  }

  @media (max-width: $nav-collapse) {
    &__top {
      &__items {
        display: none;
      }

      .navigation--opened &__logo {
        opacity: 0;
      }
    }
  }
}

.overflow-hidden-navbar {
  @media (max-width: $nav-collapse) {
    overflow: hidden;
  }
}
