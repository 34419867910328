@import "../variables/_";

#root:not(:empty) + .loader {
  opacity: 0;
  pointer-events: none;
  //transform: scale(5);
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1100;
  background: $color-dark-20;
  transition: opacity 0.5s ease-in, transform 0.5s ease-in;

  &-circle {
    margin: 0 12px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    animation: loaderCircleAnim 0.6s infinite ease-in-out;

    @media ($max-xs) {
      width: 25px;
      height: 25px;
      margin: 0 8px;
    }

    &-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-1 {
      background: linear-gradient(125.05deg, #E6DADA 20.62%, #274046 107.32%);
      animation-delay: 0s;
    }

    &-2 {
      background: linear-gradient(90deg, #FFB347 0%, #FFCC33 100%);
      animation-delay: -0.15s;
    }

    &-3 {
      background: linear-gradient(90deg, #11998E 0%, #38EF7D 100%);
      animation-delay: -0.3s;
    }

    &-4 {
      background: linear-gradient(90deg, #BC4E9C 0%, #F80759 100%);
      animation-delay: -0.45s;
    }
  }

  @keyframes loaderCircleAnim {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(50%);
    }
  }

  //.sk-cube {
  //  width: 33%;
  //  height: 33%;
  //  background: #E6DADA;
  //  float: left;
  //  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  //
  //  &-grid {
  //    position: absolute;
  //    top: 50%;
  //    left: 50%;
  //    transform: translate(-50%, -50%);
  //    width: 80px;
  //    height: 80px;
  //  }
  //
  //  &7 {
  //    animation-delay: 0s;
  //  }
  //
  //  &4, &8 {
  //    animation-delay: 0.1s;
  //  }
  //
  //  &1, &5, &9 {
  //    animation-delay: 0.2s;
  //  }
  //
  //  &2, &6 {
  //    animation-delay: 0.3s;
  //  }
  //
  //  &3 {
  //    animation-delay: 0.4s;
  //  }
  //}
  //
  //@keyframes sk-cubeGridScaleDelay {
  //  0%,
  //  70%,
  //  100% {
  //    transform: scale3D(1, 1, 1);
  //  }
  //  35% {
  //    transform: scale3D(0, 0, 1);
  //  }
  //}
  //<!--  <div class="sk-cube-grid">-->
  //<!--    <div class="sk-cube sk-cube1"></div>-->
  //<!--    <div class="sk-cube sk-cube2"></div>-->
  //<!--    <div class="sk-cube sk-cube3"></div>-->
  //<!--    <div class="sk-cube sk-cube4"></div>-->
  //<!--    <div class="sk-cube sk-cube5"></div>-->
  //<!--    <div class="sk-cube sk-cube6"></div>-->
  //<!--    <div class="sk-cube sk-cube7"></div>-->
  //<!--    <div class="sk-cube sk-cube8"></div>-->
  //<!--    <div class="sk-cube sk-cube9"></div>-->
  //<!--  </div>-->
}
