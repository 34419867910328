@import "../variables/_";

@media ($max-md) {
  .overflow-hidden-md {
    overflow: hidden;
  }
}

@media screen and ($max-sm) {
  .m-auto-sm {
    margin-left: auto;
    margin-right: auto;
  }

  .text-center-sm {
    text-align: center;
  }
}

@media screen and ($max-xs) {
  .m-auto-xs {
    margin-left: auto;
    margin-right: auto;
  }

  .text-center-xs {
    text-align: center;
  }
}

.full-height {
  min-height: calc(100vh);
}

.link-reset {
  color: inherit !important;
  text-decoration: none !important;

  &:hover {
    color: inherit !important;
    text-decoration: none !important;
  }
}
