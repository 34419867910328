@import '../../../styles/variables/_';
@import '../../../styles/base/mixins';

.services__message {
  padding: 25px 15px 0 25px;
  display: flex;
  align-items: flex-start;

  @media ($max-md) {
    padding: 25px 10px 0 15px;
  }

  &__container {
    position: absolute;
    padding-bottom: 25px;
  }

  &__avatar {
    width: 44px;
    height: 44px;
    &_column {
      padding-right: 15px;
    }
  }

  &__info {
    width: 100%;
    display: flex;
    justify-content: space-between;

    &_name {
      font-size: $font-size-def;
      color: $color-link;
      font-weight: 500;
      line-height: 1.2;
    }

    &_time {
      color: $color-text-darkest;
      font-size: 1.1rem;

      img {
        margin-right: 4px;
      }
    }
  }

  &__body {
    margin-top: 5px;
    font-size: $font-size-sm;
    line-height: 1.45;
    color: white;

    &_column {
      flex: 1;
    }
  }
}
