@import '../../../styles/variables/_';
@import '../../../styles/base/mixins';

.agency-expertise__sidebar {
  display: flex;
  flex-direction: column;
  background: $color-dark-25;
  min-width: 180px;
  flex: 0 0 28%;
  padding: 20px 0 20px;

  &__title {
    color: $color-text-darkest;
    padding: 0 20px 15px 20px;
    line-height: 1.1;
  }

  &__tab {
    @include reset-button;
    padding-right: 10px;
    padding-left: 40px;
    color: $color-text-def;
    text-align: left;
    line-height: 2.2;
    height: 2.2em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &--active {
      background: $color-grey
    }

    img {
      display: inline-block;
      width: 15px;
      margin-right: 7px;
    }

    @media ($max-md) {
      padding-left: 20px;
    }

    @media ($max-sm) {
      font-size: $font-size-sm;
    }
  }
}
