@import '../../../styles/variables/_';
@import '../../../styles/base/mixins';

.service {
  padding: 23px 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $color-grey;
  border-radius: 12px;
  height: 100%;

  @media ($max-xs) {
    padding: 16px;
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  &-wrapper {
    padding: 15px;
    outline: none;
    height: 100%;
  }

  &--all-text-wrapper {
    height: 100%;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
  }

  &--image-wrapper {
    max-width: 150px;
    width: 100%;

    @media ($max-xs) {
      margin-bottom: 20px;
      max-width: 78px;
    }

    & img {
      width: 100%;
    }
  }

  &__title {
    margin-bottom: 13px;
    font-size: $font-size-def;
    color: $color-text-lighter;
    text-transform: uppercase;
  }

  &__text {
    max-width: 236px;
    margin-bottom: 13px;
    font-size: $font-size-xs;
  }

  &__learn-more {
    width: 165px;
  }
}
