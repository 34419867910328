@import "../../styles/base/mixins";
@import "../../styles/variables/_";

.progress-bar-custom {
  position: relative;
  width: 100%;
  height: 20px;
  background-color: $color-grey;
  border-radius: 50rem;
}

.filler {
  background-color: #1769E6;
  height: 100%;
  border-radius: inherit;
  transition: width 0.5s ease-in;
}
