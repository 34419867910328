@import "../../styles/variables/_";
@import "../../styles/base/mixins";

.contact-us {
  margin-bottom: 100px;

  &__form {
    display: flex;
    flex-direction: column;

    &__row {
      display: flex;
      width: 100%;
      margin-bottom: 20px;
      flex-wrap: wrap;

      label {
        flex: 0 0 70px;
        width: 70px;
        padding-right: 15px;
        font-size: $font-size-md;
        line-height: 36px;

        @media ($max-xs) {
          font-size: $font-size-sm;

          flex: 0 0 60px;
          width: 60px;
        }
      }
    }

    &__input, &__textarea {
      flex: 1;
      background: $color-grey;
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
      border-radius: 3px;
      border: 0;
      padding-left: 15px;
      color: white;
      outline: none;
    }

    &__input {
      height: 36px;
    }

    &__textarea {
      padding-top: 0.5em;
      height: 150px;
    }

    &__error {
      font-size: $font-size-sm;
      color: $color-red;

      &s {
        margin-top: 4px;
        flex: 0 0 100%;
        text-align: center;
      }
    }

    &__submit {
      &_wrapper {
        text-align: center;
      }
    }
  }

  &__window__content {
    padding: 25px 35px;

    @media ($max-xs) {
      padding: 20px 15px 30px 15px;
    }
  }
}
