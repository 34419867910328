@import '../../../styles/variables/_';
@import '../../../styles/base/mixins';

.services__sidebar__tab {
  display: flex;
  padding: 9px 5px 9px 15px;
  border-left-style: none;
  border-left-width: 4px;
  cursor: pointer;
  transition-duration: 0.2s;

  &:hover {
    background: rgba(0, 0, 0, 0.07);
  }

  &:hover &_avatar {
    transform: rotateY(180deg);
    transition-duration: 500ms;

    img {
      transform: rotateY(-180deg);
      transition-duration: 500ms;
    }
  }

  &--active {
    border-left-style: solid;
    background: rgba(0, 0, 0, 0.15) !important;
  }

  &_avatar {
    margin-right: 10px;
    flex: 0 0 50px;

    img {
    }
  }

  &_body {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 0;

    &_top {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 10px;
    }
  }

  &_title {
    font-size: $font-size-md;
  }

  &_subtitle {
    font-size: $font-size-xs;
    color: $color-text-darkest;
    max-width: 90%;
  }

  &_title, &_subtitle {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1;
  }

  &_time {
    font-size: $font-size-xs;
    color: $color-text-darkest;
    white-space: nowrap;
    padding-left: 10px;
  }
}
