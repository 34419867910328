* {
  box-sizing: border-box;

  &:after,
  &:before {
    box-sizing: border-box;
  }
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

table {
  border-collapse: collapse;
}

html {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  // Set the global font-size in order
  // to be able to have `1rem = 10px`.
  font-size: 62.5%;
}

body {
  height: 100%;
  width: 100%;
  margin: 0;
}
