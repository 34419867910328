@import '../../styles/variables/_';
@import '../../styles/base/mixins';

$screenChangePoint1: 1199px;
$windVertScreenWidth: 1000px;

.hero {
  padding-top: 120px;

  @media (max-width: $windVertScreenWidth) {
    padding-top: 90px;
  }

  &__grid {
    display: flex;
    justify-content: space-between;

    @media (max-width: $windVertScreenWidth) {
      flex-direction: column;
    }

    &__column {
      &-left {
        display: flex;
        flex: 0 0 70%;
        flex-direction: column;
        padding-right: 20px;

        @media (max-width: $screenChangePoint1) {
          flex: 0 0 60%;
        }

        @media (max-width: $windVertScreenWidth) {
          flex: 0 0 100%;
          flex-direction: column-reverse;
          align-items: center;
          padding-right: 0;
        }
      }

      &-right {
        flex: 1;
        display: flex;
        align-items: center;
      }
    }
  }

  &__window {
    &-video {
      position: relative;
      margin-top: 20px;

      @media (max-width: $windVertScreenWidth) {
        margin-top: 0;
        margin-bottom: 25px;
      }

      &__video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;

        &_outer {
          width: 100%;
          padding-bottom: 56.25%;
          position: relative;
        }
      }

      &__button {
        position: absolute;
        bottom: 10%;
        left: 50%;
        transform: translateX(-50%);
        max-width: 350px;
        width: 100%;
        padding: 0 15px;

        a {
          font-size: $font-size-sm !important;
          font-weight: 600 !important;
        }

        @media ($max-xs) {
          max-width: 100%;
        }
      }
    }

    &-2 {
      &__content {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media ($max-xs) {
          align-items: flex-end;
        }
      }

      &__top-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &_icon {
          width: 60px;
          margin-right: 20px;
          @media ($max-md) {
            width: 50px;
          }
          @media ($max-xs) {
            width: 35px;
            margin-right: 15px;
          }
        }

        &_text {
          flex: 1;
          font-size: 2.2rem;
          font-weight: 500;
          @media ($max-lg) {
            font-size: 2rem;
          }
          @media ($max-md) {
            font-size: $font-size-md;
          }
          @media ($max-xs) {
            font-size: $font-size-def;
          }
        }
      }

      &__button {
        margin-top: 20px;

        @media ($max-xs) {
          min-width: 120px !important;
          margin-top: 15px;

          .button {
            height: 26px;
            font-size: $font-size-sm;
          }
        }
      }
    }

    &-whyus {
      @media (max-width: $windVertScreenWidth) {
        margin-top: 25px;
      }

      &__title {
        font-size: $font-size-xl;
        margin-bottom: 15px;
      }

      &__item {
        display: flex;
        align-items: center;
        padding: 0;
        flex: 0 0 50%;
        transition-duration: $anim-duration-long;

        @media (min-width: $windVertScreenWidth + 1) {
          flex: 0 0 100%;
        }

        @media ($max-xs) {
          flex: 0 0 100%;
        }

        &_img {
          width: 55px;
          margin-right: 20px;

          @media ($max-sm) {
            width: 44px;
            margin-right: 15px;
          }
        }

        &_text {
          font-weight: 500;
          padding: 20px 25px 20px 0;
          border-bottom: 1px solid $color-grey;
        }

        &s {
          display: flex;
          flex-wrap: wrap;
        }
      }
    }
  }
}

