@import '../../styles/variables/_';
@import '../../styles/base/mixins';

.avatar {
  background: #E5E5E5;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 50%;
    height: 50%;
    object-fit: contain;
  }
}
