@import '../../../styles/variables/_';
@import '../../../styles/base/mixins';
@import "../Navigation.variables";

.navigation__side {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  max-width: 380px;
  background-color: $nav-side-bg;
  color: $NavSideColor;
  box-shadow: 1px 0 7px rgba(0, 0, 0, 0.3);
  flex-direction: column;
  transform: translateX(100%);
  transition-duration: $anim-duration-long;
  display: flex;

  &_top {
    top: 0;
    left: 0;
    padding: 20px 15px 15px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &__logo {
      max-width: 32px;
      width: 100%;

      img {
        width: 100%;
      }
    }

    &__close {
      @include reset-button;
    }
  }

  &__item {
    min-width: 200px;
    height: 34px;
    margin-bottom: 25px;

    &s {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  &__darkpanel {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 0;
    transition: opacity $anim-duration-long;
    pointer-events: none;
    backdrop-filter: $blur-backdrop;
  }

  @media (max-width: $nav-collapse) {
    .navigation--opened & {
      transform: translateX(0);

      &__darkpanel {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  @media ($max-xs) {
    max-width: 100%;
    &__darkpanel {
      backdrop-filter: none;
    }
  }
}
