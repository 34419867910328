@import '../../styles/variables/_';
@import '../../styles/base/mixins';

.services {
  &__content {
    display: flex;
    position: relative;
  }

  .window__content {
    overflow: hidden;
  }

  &__list-dash {
    margin: 0;
    list-style-type: none;
    padding-left: 0;

    & > li:before {
      content: "-";
      margin-right: 0.3em;
    }
  }

  @media ($max-sm) {
    &__sidebar {
      max-width: 100%;
      width: 100%;
      transition-duration: 0.4s;
    }

    &__body {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $color-dark-30;
      transition-duration: 0.4s;
      transform: translateX(100%);
    }

    &__content--opened &__sidebar {
      transform: translateX(-20%);
    }

    &__content--opened &__body {
      transform: translateX(0);
    }
  }
}
