@import '../../styles/variables/_';
@import '../../styles/base/mixins';

.window {
  width: 100%;
  box-shadow: $box-shadow-default;
  border-radius: $border-radius-default;
  z-index: 2;
  position: relative;
  display: flex;
  flex-direction: column;

  &__header {
    background: $color-dark-25;
    border-top-left-radius: $border-radius-default;
    border-top-right-radius: $border-radius-default;
    border-bottom: 1px solid black;
    border-top: 1px solid $color-grey;
    min-height: 24px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    font-size: $font-size-def;
    line-height: 1.15;

    @media ($max-xs) {
      padding: 0 10px;
      font-size: $font-size-sm;
    }

    &_center {
      position: absolute;
      top:0;
      left: 0;
      height: 100%;
      width: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__content {
    background: $color-dark-transparent;
    border-bottom-left-radius: $border-radius-default;
    border-bottom-right-radius: $border-radius-default;
    overflow: auto;
    flex: 1;

    &--with-pad {
      padding: 20px 30px;

      @media ($max-xs) {
        padding: 15px 20px;
      }
    }
  }
}
