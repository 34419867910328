@import "../../styles/variables/_.scss";

.about-us {
  &__window {
    margin: 0 auto;
    max-width: 810px;
    width: 100%;

    &__content {
      padding: 50px 15px;

      @media ($max-xs) {
        padding: 35px 15px 15px 15px;
      }

      &-inner {
        padding: 63px 20px 45px 20px;
        margin: 0 auto;
        max-width: 605px;
        width: 100%;

        @media ($max-xs) {
          padding: 20px 15px 30px 15px;
        }


        &__image {
          margin-bottom: 35px;
          max-width: 150px;
          width: 100%;

          @media ($max-xs) {
            margin-bottom: 20px;
            max-width: 120px;
          }

          & img {
            width: 100%;
          }
        }

        &__text {
          margin-bottom: 20px;
          font-size: $font-size-def;
          color: $color-text-lighter;

          @media ($max-xs) {
            font-size: $font-size-sm;
          }
        }
      }
    }
  }
}
