@import '../../styles/variables/_';
@import '../../styles/base/mixins';

.footer {
  flex-shrink: 0;
  padding: 20px 0;
  background-color: $color-dark-transparent;
  z-index: 2;
  position: relative;

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ($max-md) {
      flex-direction: column;
    }
  }

  &__copyright {
    display: flex;
    align-items: center;

    &__logo {
      width: 32px;
      margin-right: 15px;

      img {
        width: 100%;
      }
    }

    &__text {
      @media ($max-md) {
        font-size: $font-size-sm;
        text-align: center;
      }
    }

    @media ($max-md) {
      order: 3;
    }

    @media ($max-xs) {
      flex-direction: column;
      &__logo {
        margin: 0 0 15px;
      }
    }
  }

  &__social-button {
    margin-left: 5px;
    min-width: 50px;

    img {
      height: 14px;
    }

    &s {
      display: flex;
      @media ($max-md) {
        margin-bottom: 35px;
      }
    }
  }

  &__nav-button {
    margin-left: 10px;
    font-size: $font-size-sm;
    font-weight: 500;
    min-width: 115px;

    @media ($max-xs) {
      margin: 0 0 15px;
      min-width: 200px;
    }

    &s {
      display: flex;
      @media ($max-md) {
        margin-bottom: 35px;
      }

      @media ($max-xs) {
        flex-direction: column;
      }
    }
  }
}
